import { useState, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { Order } from '@/types';
import { Segment } from '@/types/Segment';
import { Box, Stack } from '@mui/material';
import { ConfirmRemoveSegmentModal } from './ConfirmRemoveSegmentModal';
import { SegmentActionMenu } from './SegmentActionMenu';
import { filterDeletedItems } from '@/utils/filterDeletedItems';
import { SegmentOrderDisplay } from './SegmentOrderDisplay';
import { ShipmentType } from '@/types/ShipmentType';
import { isShipmentInternational } from '@/utils/shipments/isShipmentInternational';
import { SegmentMenu } from './SegmentMenu';

interface SegmentsProp {
  segments: Array<Segment>;
  orders: Array<Order>;
  itemsTotal: number;
  currentSegmentId: string;
  onSegmentClicked: (segment: Segment) => void;
  onEditSegment: (segment: Segment) => void;
  onRemoveSegment: (segment: Segment) => void;
}

interface ActionState {
  selectedSegment: Segment | null;
  actionMenuAnchorEl: HTMLElement | null;
  isActionMenuOpen: boolean;
  isEditModalOpen: boolean;
  isConfirmRemoveModalOpen: boolean;
}

const SegmentButton = styled.button`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 4px;
  background: #f2f4f6;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.4);
  border: none;
  cursor: pointer;
  width: 120px;
  font-weight: 500;

  &.active {
    border: 1px solid #1d4e89;
  }
  &.active,
  &.active .segOrdNum {
    color: rgba(0, 0, 0, 0.87);
  }
`;

export function SegmentsList({
  segments,
  itemsTotal,
  currentSegmentId,
  onSegmentClicked,
  onEditSegment,
  onRemoveSegment,
  orders,
}: SegmentsProp) {
  const segmentsToDisplay = filterDeletedItems(segments);
  const isLastSegment = segmentsToDisplay.length === 1;

  const [actionState, setActionState] = useState<ActionState>({
    actionMenuAnchorEl: null,
    isActionMenuOpen: false,
    isEditModalOpen: false,
    isConfirmRemoveModalOpen: false,
    selectedSegment: null,
  });

  const onActionMenuClose = () => {
    setActionState({
      ...actionState,
      actionMenuAnchorEl: null,
      isActionMenuOpen: false,
    });
  };

  const onActionMenuButtonClick = (
    segment: Segment,
    event: MouseEvent<HTMLButtonElement>
  ) => {
    setActionState({
      ...actionState,
      actionMenuAnchorEl: event.currentTarget,
      selectedSegment: segment,
      isActionMenuOpen: true,
    });
  };

  const openModal = (modal: string) => {
    setActionState({
      ...actionState,
      isActionMenuOpen: false,
      [modal]: true,
    });
  };

  const closeModal = (modal: string) => {
    setActionState({
      ...actionState,
      selectedSegment: null,
      [modal]: false,
    });
  };

  const onConfirmRemoveSegment = (segment: Segment) => {
    onRemoveSegment(segment);
    closeModal('isConfirmRemoveModalOpen');
  };

  const onEditSegmentChange = (segment: Segment) => {
    onEditSegment(segment);
    closeModal('isEditModalOpen');
  };

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {segmentsToDisplay.map((segment) => {
        const segmentsPotentialOrders = orders?.find(
          (order) => order?.id === segment?.orderId
        );
        const key = `${segment?.orderId}-${segment?.to}-${segment?.from}`;
        const isActive = currentSegmentId === segment.id;

        return (
          <SegmentButton
            key={key}
            className={isActive ? 'active' : ''}
            onClick={(event) =>
              isActive
                ? onActionMenuButtonClick(segment, event)
                : onSegmentClicked(segment)
            }
          >
            <SegmentOrderDisplay
              segment={segment}
              order={segmentsPotentialOrders}
            />
          </SegmentButton>
        );
      })}
      <SegmentActionMenu
        anchorEl={actionState.actionMenuAnchorEl}
        open={actionState.isActionMenuOpen}
        onClose={onActionMenuClose}
        onRemoveSegment={
          isLastSegment
            ? undefined
            : () => openModal('isConfirmRemoveModalOpen')
        }
        onEditSegment={() => openModal('isEditModalOpen')}
      />

      {actionState.isEditModalOpen && actionState.selectedSegment ? (
        <SegmentMenu
          segments={segmentsToDisplay}
          segment={actionState.selectedSegment!}
          orders={orders}
          anchorEl={actionState.actionMenuAnchorEl}
          open={actionState.isEditModalOpen}
          onClose={() => closeModal('isEditModalOpen')}
          onSubmit={onEditSegmentChange}
          submitButtonLabel="Save"
          segmentSelectLabel="Segment Order Id"
        />
      ) : null}

      {actionState.isConfirmRemoveModalOpen && actionState.selectedSegment ? (
        <ConfirmRemoveSegmentModal
          open={actionState.isConfirmRemoveModalOpen}
          segment={actionState.selectedSegment!}
          itemsTotal={itemsTotal}
          onCancel={() => closeModal('isConfirmRemoveModalOpen')}
          onConfirm={onConfirmRemoveSegment}
        />
      ) : null}
    </Stack>
  );
}

interface SelectableOrder {
  id: string;
  number: string;
  shipmentType?: ShipmentType;
}
export function OrderTabLegend({ orders }: { orders: SelectableOrder[] }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
      {!orders.every((order) =>
        order.shipmentType?.includes('INTERNATIONAL_')
      ) ? (
        <>
          <Box
            sx={{
              bgcolor: '#1D4E89',
              borderRadius: '100%',
              height: '1em',
              width: '1em',
              marginRight: '.5em',
            }}
          />
          <Box sx={{ marginRight: '1.5em' }}>Domestic </Box>
        </>
      ) : null}
      {orders.some((order) => isShipmentInternational(order.shipmentType)) ? (
        <>
          <Box
            sx={{
              bgcolor: '#00C7BE',
              borderRadius: '0',
              height: '1em',
              width: '1em',
              marginRight: '.5em',
            }}
          />
          <Box>International</Box>
        </>
      ) : null}
    </Box>
  );
}
