import { ReactNode } from 'react';
import { OutlinedAddButton } from '@/components/common/Button';
import { Order, Segment } from '@/types';
import { usePopupState } from '@/hooks';
import { SegmentMenu } from './SegmentMenu';

interface AddSegmentButtonProps {
  onAddSegment: (segment: Segment) => void;
  children: ReactNode;
  orders: Array<Order>;
  segments: Array<Segment>;
}

export function AddSegmentButton({
  onAddSegment,
  children,
  orders,
  segments,
}: AddSegmentButtonProps) {
  const { popupAnchorEl, isPopupOpen, openPopup, closePopup } = usePopupState();

  const handleOnAddSegment = (segment: Segment) => {
    closePopup();
    onAddSegment(segment);
  };

  return (
    <>
      <OutlinedAddButton onClick={openPopup}>{children}</OutlinedAddButton>
      <SegmentMenu
        segments={segments}
        orders={orders}
        anchorEl={popupAnchorEl}
        open={isPopupOpen}
        onClose={closePopup}
        onSubmit={handleOnAddSegment}
        submitButtonLabel="Create"
        segmentSelectLabel="Order for New Segment"
      />
    </>
  );
}
